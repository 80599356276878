import design from "../assets/images/features/design.png";
import support from "../assets/images/features/support.png";
import brand from "../assets/images/features/brand.png";
import ux from "../assets/images/features/ux.png";

const Features = [
  {
    srcImage: design,
    titleEN: "Digital Design",
    titleAR: "",
    subtitleEN:
      "See your authentic mission, and values come to life with a unique brand image.",
  },
  {
    srcImage: brand,
    titleEN: "New Brands",
    titleAR: "",
    subtitleEN:
      "See your authentic mission, and values come to life with a unique brand image.",
  },
  {
    srcImage: ux,
    titleEN: "User Experience",
    titleAR: "",
    subtitleEN:
      "See your authentic mission, and values come to life with a unique brand image.",
  },
  {
    srcImage: support,
    titleEN: "Helping Support",
    titleAR: "",
    subtitleEN:
      "See your authentic mission, and values come to life with a unique brand image.",
  },
];

export default Features;
