import doc from "../assets/images/whyfeatures/contract.png";
import rate from "../assets/images/whyfeatures/fair-trade.png";
import money from "../assets/images/whyfeatures/refund.png";
import fast from "../assets/images/whyfeatures/shield.png";

const WhyFeatures = [
  {
    titleEN: "Free Documention",
    titleAR: "",
    subtitleEN:
      "We will provide a free documentation which will be helpful to customize the theme easily.",
    subtitleAR: "",
    descriptionEN: `All of our products come with comprehensive documentation. You can access it directly from the dashboard, or download it for offline`,
    image: doc,
  },
  {
    titleEN: "100% Response Rate",
    titleAR: "",
    subtitleEN:
      "We will ensure 100% response rate within 48 hours. Just submit us any query.",
    subtitleAR: "",
    image: rate,
  },
  {
    titleEN: "30 Days Money Back",
    titleAR: "",
    subtitleEN:
      "You will get 100% money back guarantee if we will be failure to solve your problem.",
    subtitleAR: "",
    image: money,
  },
  {
    titleEN: "Fast & Speedy!",
    titleAR: "",
    subtitleEN:
      "There are plenty of fast themes you can download. Our themes are very fast to load.",
    subtitleAR: "",
    image: fast,
  },
];

export default WhyFeatures;
