import React from "react";

const Features = ({ fea }) => {
  return (
    <li className="why__card">
      <div className="icon mx-auto mb-3 w-100">
        <figure className="image">
          <img src={fea.image} alt="" />
        </figure>
      </div>
      <h4 className="mb-3 ">{fea.titleEN}</h4>
      <p className="mb-3">{fea.subtitleEN}</p>
    </li>
  );
};

export default Features;
