import React, { useEffect, useState } from "react";

import heroIMAGE from "../assets/images/hero/Image_01.png";
import des1 from "../assets/images/hero/Subscribe-now.png";
import tech1 from "../assets/images/hero/tech1.png";
import tech2 from "../assets/images/hero/tech2.png";
import tech3 from "../assets/images/hero/tech3.png";
import tech4 from "../assets/images/hero/tech4.png";
import tech5 from "../assets/images/hero/tech5.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import WhyFeatures from "../data/WhyFeatures";
import FeaturesData from "../data/Features";

import products from "../data/Products";
import ProductCard from "../components/ProductCard";
import Features from "../components/Features";
import FreebiesCard from "../components/FreebiesCard";
import Techs from "../data/Techs";

const Home = ({ categories, media }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [freebiesProducts, setFreebiesProducts] = useState([]);

  useEffect(() => {
    const popularProducts = products.filter((prod) => prod.isPopular);
    const freebiesProducts = products.filter((prod) => prod.isFreebies);
    setFilteredProducts(popularProducts);
    setFreebiesProducts(freebiesProducts);
  }, []);

  return (
    <>
      <div id="home" className="default-padding">
        <img className="des-1" src={des1} alt="" />
        <img className="tech-1" src={tech1} alt="" />
        <img className="tech-2" src={tech2} alt="" />
        <img className="tech-3" src={tech3} alt="" />
        <img className="tech-4" src={tech4} alt="" />
        <img className="tech-5" src={tech5} alt="" />

        <div className="container container-md container-sm">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="hero-text">
                <h1 className="title">
                  Get <span>Professional</span> Templates For Your Business!
                </h1>
                <p className="sub">
                  We are trying to provide the best and the most quality full
                  templates.
                  <br />
                  Those are made by our professional team members!
                </p>
                <div className="product-search-form">
                  <div className="bar-search">
                    <input
                      type="text"
                      placeholder="Search Product..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                  <span className="icon">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <figure>
                <img src={heroIMAGE} alt="" />
              </figure>
            </div>
          </div>
        </div>
      </div>

      <section id="features" className="default-padding">
        <div className="container">
          <div className="mb-4">
            <h2 className="sec-title">features</h2>
            <p className="sec-sub">We Provide Exciting Feature</p>
          </div>
          <div className="features">
            {FeaturesData &&
              FeaturesData.map((fea, index) => (
                <div className="featured-item style-1" key={index}>
                  <div className="featured-icon mb-3">
                    <img decoding="async" src={fea.srcImage} alt="Icon" />
                  </div>
                  <div className="featured-desc">
                    <div className=" mb-3">
                      <h5 className="featured-title">{fea.titleEN}</h5>
                    </div>
                    <p>{fea.subtitleEN}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      <section id="popular" className="popular default-padding">
        <div className="container container-md container-sm">
          <h2 className="sec-title">Our Popular Products</h2>
          <p className="sec-sub">Discover our most sought-after products</p>
          <div className="row">
            {filteredProducts &&
              filteredProducts.map((prod, index) => (
                <ProductCard key={index} prod={prod} />
              ))}
          </div>
        </div>
      </section>

      <div id="why" className="default-padding">
        <div className="container container-md container-sm">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="sec-title mb-4">
                Why Choose Us Outstanding Digital Products
              </h2>
              <p className="sec-sub mb-4">
                Our all products have extensive documentation for smooth
                installation, setup, and use. These products are very Clean &
                Modern Design. All of our products are created by our
                professional team members!
              </p>
              <p className="mb-4">
                Almost all of our products are Unique effects and functionality,
                are Fully Responsive, ready to go, and well documented.
              </p>
              <button className="cs_btn primary text-capitalize">
                discover more
              </button>
            </div>
            <div className="col-md-6">
              <ul className="features">
                {WhyFeatures &&
                  WhyFeatures.map((item, index) => (
                    <Features key={index} fea={item} />
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div id="products" className="default-padding">
        <div className="container">
          <div className="mb-5">
            <h2 className="sec-title text-center">
              Check Out Our Newest Product
            </h2>
            <p className="sec-sub text-center">
              Choose your desired WordPress Themes & Templates from our
              collection of Responsive.
              <br />
              These all products are responsive & SEO friendly. We created our
              custom WordPress Themes
              <br />
              Templates, Plugins & Wallpapers that make thecustomer experience
              even more pleasant.
            </p>
          </div>
          <div className="row">
            {products &&
              products
                .slice(0, 8)
                .map((prod, index) => <ProductCard key={index} prod={prod} />)}
          </div>
          <button className="cs_btn primary">View More</button>
        </div>
      </div>

      {freebiesProducts.length > 0 && (
        <div id="free" className="default-padding">
          <div className="container">
            <div className="mb-5">
              <h2 className="sec-title">Most Popular Freebies</h2>
              <p className="sec-sub">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Reprehenderit minima dolores, omnis nesciunt ex vero, quia
                explicabo culpa iste atque excepturi nostrum! Dolorum
                repellendus perspiciatis nesciunt mollitia beatae, sunt aut.
              </p>
            </div>
            <div className="row ps-0 align-items-center">
              <div className="col-3 side">
                <h4 className="h3 text-white">
                  Browse this month’s best freebies themes
                </h4>
                <p className="sec-sub text-white">
                  The best web themes and templates have arrived.
                </p>
                <button className="cs_btn">see all products</button>
              </div>
              <div className="col-9">
                <div className="freebiesProducts">
                  {freebiesProducts &&
                    freebiesProducts.map((prod, index) => (
                      <FreebiesCard key={index} prod={prod} />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* 
      <div id="technologies" className="default-padding">
        <div className="page-title">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-5">
                  <h2 className="sec-title">An innovative mindset:</h2>
                  <p className="sec-sub">
                    Un état d'esprit innovant. Nos profils techniques utilisent
                    des technologies de pointe pour assurer la qualité de nos
                    livrables dans chaque service proposé.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="techs">
                  {Techs &&
                    Techs.map((tech, index) => (
                      <figure className="image" key={index}>
                        <img src={tech.src} alt="" />
                      </figure>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Home;
