import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const LazyLoadImageWrapper = ({ src, alt }) => {
  return (
    <LazyLoadImage
      useMap={src}
      // decoding=""
      loading="lazy"
      srcSet={src}
      alt={alt}
      src={src}
    />
  );
};

export default LazyLoadImageWrapper;

// This component serves as a wrapper for the LazyLoadImage component,
// simplifying the syntax and providing a cleaner interface for lazy loading images.

// Usage:
// Instead of directly using <LazyLoadImage />, use this component like:
// <LazyLoadImageWrapper src={imageUrl} alt={imageAlt} />

// Benefits:
// 1. Encapsulation: Encapsulates the details of lazy loading images, making the code
//    cleaner and more modular by separating concerns.
// 2. Simplified Syntax: Provides a concise syntax for using LazyLoadImage, reducing
//    boilerplate code in components that use lazy-loaded images.
// 3. Maintenance: If changes are needed in the lazy loading implementation or if
//    a different library/component needs to be used, modifications can be done
//    centrally in this wrapper, minimizing the impact on other components.
