import borosReact from "../assets/images/products/boros-react.png";
import borosHtml from "../assets/images/products/boros.png";

const Products = [
  {
    title: "Boros - Portfolio HTML template",
    poster: borosHtml,
    previewLink: "https://www.boros.lafkiri.com/",
    type: "html",
    date: "April 1, 2023",
    version: "1.0.5",
    sales: 3,
    price: "$59.99",
    isPopular: true,
    isFreebies: false,
  },
  {
    title: "Boros - React Multi-language Portfolio template",
    poster: borosReact,
    previewLink: "#",
    type: "react",
    date: "April 1, 2022",
    version: "1.0.5",
    sales: 3,
    price: "$49.99",
    isPopular: true,
    isFreebies: false,
  },
  {
    title: "Martex - HTML Agency template",
    poster: borosReact,
    previewLink: "#",
    type: "html",
    date: "April 1, 2022",
    version: "1.0.5",
    sales: 3,
    price: "$59.99",
    isPopular: true,
    isFreebies: false,
  },
];

export default Products;
