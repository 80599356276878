import React from "react";

const productCard = ({ prod }) => {
  return (
    <div className="col-md-4 mb-4">
      <div className="card">
        <div className="pr__card-header">
          <figure className="mb-4">
            <img src={prod.poster} alt="" />
          </figure>
          <div className="buttons d-flex align-items-center flex-column gap-3">
            <button className="cs_btn">Preview</button>
            <button className="cs_btn primary">Details</button>
          </div>
        </div>
        <div className="card-body">
          <h4 className="mb-4">
            <a href="#" className="card-title">
              {prod.title}
            </a>
          </h4>
          <div className="mb-3 d-flex flex-column gap-2">
            <span className="pr__info">Released On: {prod.date}</span>
            <span className="pr__info">Version: {prod.version}</span>
          </div>
          <div className="mb-3">
            <h3 className="pr__price d-flex justify-content-between w-100 gap-2">
              {prod.price} <span className="sale-cnt">{prod.sales} Sales</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default productCard;
