import React from "react";

const FreebiesCard = ({ prod }) => {
  return (
    <div className="fr__card">
      <div className="fr__card-header">
        <img src={prod.poster} alt="" />
      </div>
      <div className="card-body">
        <h4 className="mb-4 d-flex flex-column">
          <a href="#" className="card-title">
            {prod.title}
          </a>
          <span> {prod.isFreebies ? "free" : ""}</span>
        </h4>
      </div>
    </div>
  );
};

export default FreebiesCard;
