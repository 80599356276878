import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/images/logo/logo.jpg";

import fr from "../assets/images/navbar/fr.png";
import en from "../assets/images/navbar/en.png";
import ar from "../assets/images/navbar/ar.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faGear,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";

import sounds1 from "../assets/sounds/click-notification.mp3";
import sounds2 from "../assets/sounds/click-button.mp3";
import LazyLoadImageWrapper from "./LazyLoadImageWrapper";

import { LANGUAGES } from "../i18n";
import { useTranslation } from "react-i18next";

const Navbar = ({ media, setIsActiveSearchBar, isActiveSearchBar }) => {
  const { i18n, t } = useTranslation();

  const pages = [
    "html",
    "ui",
    "plugins",
    "cms",
    "eCommerce",
    "forums",
    "blogging",
    "more",
  ];

  const [isLangActive, setisLangActive] = useState(false);
  const handleSettings = (event) => {
    // Stop the event propagation to prevent it from reaching the document click listener
    event.stopPropagation();

    // Play the click sound
    const clickSound = new Audio(sounds2);
    clickSound.play();
    setisLangActive(!isLangActive);
  };

  const handleSearchBar = () => {
    // Play the click sound
    const clickSound = new Audio(sounds1);
    clickSound.play();
    // Change the value of setIsActiveSearchBar for showing the search-bar screen
    setIsActiveSearchBar(!isActiveSearchBar);
  };

  // Add an active class to the navbar when scrolling
  const [isActiveNav, setIsActiveNav] = useState(false);
  useEffect(() => {
    const handleNavbar = () => {
      let x = window.scrollY;
      if (x >= 120) {
        setIsActiveNav(true);
      } else {
        setIsActiveNav(false);
      }
    };

    // Initial check for scroll position
    handleNavbar();

    document.addEventListener("scroll", handleNavbar);

    return () => {
      document.removeEventListener("scroll", handleNavbar);
    };
  }, []);

  // Set an active class for the mobile nav menu
  const [isActiveMenuNav, setIsActiveMenuNav] = useState(false);

  const handleMobileNavMenu = () => {
    setIsActiveMenuNav(!isActiveMenuNav);
  };

  const handleDocumentClick = (event) => {
    const isLangMenu = event.target.closest(".menu-langs");
    const isMobileMenu = event.target.closest(".bars-icon");

    if (!isLangMenu) {
      // Clicked outside the language menu, remove the active class
      setisLangActive(false);
    }

    if (!isMobileMenu) {
      // Clicked outside the mobile menu, hide the menu
      setIsActiveMenuNav(false);
    }
  };

  useEffect(() => {
    // Add a click event listener to the document
    document.addEventListener("click", handleDocumentClick);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  return (
    <nav className={`navbar ${isActiveNav ? "active" : ""}`}>
      <div className="container-fluid">
        <div className="bars-icon" onClick={handleMobileNavMenu}>
          <FontAwesomeIcon icon={faBars} />

          {/* Drop-down menu */}
          <div
            className={`drop-down-menu d-flex flex-column gap-2 ${
              isActiveMenuNav ? "active" : ""
            }`}
          >
            {/* Pages Links */}
            {pages.map((page, index) => (
              <Link
                className="text-center"
                to={
                  page.toLocaleLowerCase() === "home"
                    ? "/"
                    : page.toLocaleLowerCase()
                }
                key={index}
              >
                {t(`navbar.${page.toLowerCase()}`)}
              </Link>
            ))}
          </div>
        </div>
        <Link to={"/"}>
          <div className="brand-logo d-flex align-items-center gap-3">
            <LazyLoadImageWrapper src={logo} alt={"Sofa"} />
            <span className="fw-bold text-capitalize text-black">Agency</span>
          </div>
        </Link>
        <div className="nav-items">
          {pages.map((page, index) => (
            <NavLink
              to={page.toLowerCase() === "home" ? "/" : page.toLowerCase()}
              className="nav-item"
              key={index}
              onClick={() => window.scrollTo(0, 0)}
            >
              {t(`navbar.${page.toLowerCase()}`)}
            </NavLink>
          ))}
        </div>
        <div className="buttons">
          <button className="cs_btn">Login</button>
          <button className="cs_btn primary">Sign up</button>
          <div className="position-relative" onClick={handleSettings}>
            <div className="settings">
              <FontAwesomeIcon icon={faGear} color="#526c6f" />
            </div>
            <div className={`menu-langs ${isLangActive ? "active" : ""}`}>
              <p className="menu-langs-title">{t(`navbar.language`)}</p>
              {LANGUAGES &&
                LANGUAGES.map((lang, index) => (
                  <div
                    className="lan d-flex align-items-center gap-4"
                    data-lang={lang.code}
                    key={index}
                    onClick={() => i18n.changeLanguage(lang.code)}
                  >
                    <img
                      loading="lazy"
                      src={
                        lang.code === "en" ? en : lang.code === "fr" ? fr : ar
                      }
                      alt="Sofa"
                    />
                    <span>{lang.label}</span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
