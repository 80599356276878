import React, { Suspense, useEffect, useState } from "react";

// Import Bootstrap 5
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/rtl-support.scss";
import "./assets/css/core.scss";
import "./assets/css/responsive.scss";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar";
import Home from "./pages/Home";

// Font Awesom Free Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faWhatsapp,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";

// Import i18n for use other languages in my app
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { RTL_LANGUAGES } from "./i18n";
import Footer from "./components/Footer";
import Loading from "./components/Loading";

const App = () => {
  // Init react-i18next
  const { i18n } = useTranslation();
  document.dir = i18n.dir();

  // Dynamically load Bootstrap RTL CSS when Arabic is selected
  useEffect(() => {
    if (RTL_LANGUAGES.includes(i18n.language)) {
      const bootstrapRtlLink = document.createElement("link");
      bootstrapRtlLink.rel = "stylesheet";
      bootstrapRtlLink.href =
        "https://cdn.rtlcss.com/bootstrap/v5.0.3/css/bootstrap.min.css";
      document.head.appendChild(bootstrapRtlLink);
      // Set the document head and html direction to RTL
      document.documentElement.lang = "ar";
      document.body.dir = "rtl";
      // Iterate through all elements with class "fj-gallery" and set dir="ltr"
      document.querySelectorAll(".fj-gallery").forEach((gallery) => {
        gallery.setAttribute("dir", "ltr");
      });
    } else {
      // Remove the Bootstrap RTL CSS and set direction to LTR
      const bootstrapRtlLink = document.querySelector(
        'link[href="https://cdn.rtlcss.com/bootstrap/v5.0.3/css/bootstrap.min.css"]'
      );
      if (bootstrapRtlLink) {
        bootstrapRtlLink.remove();
      }
      document.body.dir = "ltr";
    }
  }, [i18n.language]);

  // TOGGLE ACTIVE FOR MOBILE MENU
  const [isActiveSearchBar, setIsActiveSearchBar] = useState(false);

  // SOCIAL MEDIA LINKS
  const media = [
    {
      icon: <FontAwesomeIcon icon={faInstagram} />,
      link: "#",
    },
    {
      icon: <FontAwesomeIcon icon={faWhatsapp} />,
      link: "#",
    },
    {
      icon: <FontAwesomeIcon icon={faFacebook} />,
      link: "#",
    },
  ];

  // LOADING
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      // Change the value of isLoading After 1000ms (1s)
      setIsLoading(false);
    }, 1200);
  }, []);

  const categories = [
    "html",
    "ui",
    "plugins",
    "cms",
    "eCommerce",
    "forums",
    "blogging",
  ];

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Router>
          <Navbar
            media={media}
            setIsActiveSearchBar={setIsActiveSearchBar}
            isActiveSearchBar={isActiveSearchBar}
          />
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route
                index
                element={<Home media={media} categories={categories} />}
              />
            </Routes>
          </Suspense>
          <Footer media={media} />
        </Router>
      )}
    </>
  );
};

export default App;
